.btn-main {
    background-color: #f5deb3;
    color: var(--app-bg);
    border: 1px solid var(--app-bg);
    border-radius: 5px;
    font-size: 0.7em;
    height: min-content;
    margin: 5px 2px;
}
.btn-main:hover {
    background-color: #cfba94;
    border: 1px solid #000;
    cursor: pointer;
}
.btn-main:active {
    background-color: #9a8a6c;
    border: 1px solid #000;
}
.btn-main i {
    color: var(--app-bg);
}
.btn-main.selected {
    border: 1px solid #fff;
}
.btn-main.min-size.min-style.borders-right {
    border-radius: 0 5px 5px 0;
    padding: 0 2px;
    margin: 0;
}
.btn-main.app-header-back-button {
    font-size: 0.5em;
    padding: 0 5px;
}
.modal-container-tooltip .btn-main {
    border-radius: 0 0 15px 15px;
    font-size: 1em;
    margin: 0;
    width: 100%;
}
.btn-back {
    background-color: #f5deb3;
    color: var(--app-bg);
    border: 1px solid var(--app-bg);
    border-radius: 5px;
    font-size: 0.7em;
    height: min-content;
    margin: 5px 2px;
    padding: 5px;
}
.menu-hamburger:hover {
    cursor: pointer;
}
.btn-menu {
    background-color: var(--app-bg-highlight);
    border: 0;
    color: var(--link-text);
    text-decoration: unset;
    height: min-content;
    padding: 5px 10px;
    flex-basis: 100%;
    text-align: start;
    width: max-content;
}
.btn-menu:hover {
    /* text-decoration: underline; */
    border-radius: 5px;
    cursor: pointer;
    background-color: #151515 !important;
}
.btn-menu:active {
    text-decoration: underline;
}

.btn-tab {
    background-color: var(--app-bg);
    border: 0;
    border-radius: 5px 5px 0 0;
    color: wheat;
    font-size: 1.5em;
    padding: 5px 10px;
    margin: 0;
}
.btn-tab:hover {
    background-color: #585447;
    cursor: pointer;
}
.btn-tab:active {
    background-color: #37352c;
}
.btn-tab.selected {
    background-color: #75705f;
}

.worktime-buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
}
.worktime-buttons img {
    cursor: pointer;
    font-size: 0.3em;
    height: 4rem;
    width: 4rem;
}
.worktime-buttons img:hover {
    filter: brightness(0.8);
}
.worktime-buttons img:active {
    filter: brightness(0.6);
}
/* Filter button */
.btn-filter {
    background-color: unset;
    color: wheat;
    cursor: pointer;
    border: 0;
    display: flex;
    align-items: center;
    font-size: 2em;
    height: min-content;
    margin: 5px 0;
    padding: 5px;
}
.btn-filter:hover {
    
}
.btn-filter:active {
    
}

.btn-exit {
    position: absolute;
    top: 15px;
    right: 5px;
    color: var(--font-color-cw);
    cursor: pointer;
}

/* Plus button */
.plus-button {
    display: flex;
    justify-content: center;
    padding: 5px;
    background-color: black;
    border: 0;
    border-radius: 15px;
    width: 100px;
}
.plus-button:hover {
    cursor: pointer;
    background-color: #0f1013;
}
.plus-button:hover i {
    transition: all .5s;
    transform: rotate(180deg);
}

.favorite-button {
    /* position: absolute;
    top: 5px;
    right: 5px; */
    line-height: 1em;
    transition: all .5s;
    -webkit-transition: all .5s;
}

.favorite-button:hover i {
    position: relative;
    transition: all .5s;
    transform: scale(1.1);
    /* transition-timing-function: cubic-bezier(.4,0,.2,1); */
}

@media (min-width: 768px) {
    .worktime-buttons button {
        flex-basis: 20%;
        font-size: 0.5em;
    }
    .btn {
        font-size: 1em;
    }
    .btn-filter {
        font-size: 0.6em;
    }
}
@media (min-width: 1024px) {
    .worktime-buttons img {
        height: 3rem;
        width: 3rem;
    }
    .btn-filter {
        font-size: 0.7em;
    }
}
@media (min-width: 1440px) {
    .btn-tab {
        
    }
    .worktime-buttons img {
        height: 4rem;
        width: 4rem;
    }
}