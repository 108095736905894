/* Color palettes:
#1 282C34 f5deb3 B19688 DA5274 1B375F
#2 282C34 f5deb3 EF975B A5B4C5 434C41
#3 282C34 f5deb3 EBA24F B46F2B 3F4829
*/


* {
    box-sizing: border-box;
    scroll-behavior: smooth; 
}

.App {
    display: flex;
    flex-direction: column;
    background-color: var(--app-bg);
}

.app-header {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: start;
    border-bottom: 1px solid wheat;
    color: white;
    font-size: calc(10px + 2vmin);
    text-align: center;
}

.app-header h1 {
    font-size: .8em;
    flex-basis: 100%;
    margin: 0;
}

.app-header .menu-hamburger {
    position: relative;
    left: 0;
    margin-left: 0.5em;
    display: flex;
    align-content: center;
    align-items: center;
    font-size: 2em;
}
.app-header .menu-hamburger > *:not(:first-child) {
    margin-left: 0.5em;
}
.app-header .app-time {
    position: absolute;
    right: 0;
    font-size: 0.5em;
    margin-right: 0.5em;
    display: flex;
    align-content: center;
    align-items: center;
}
.app-header .app-notice-banner {
    font-size: 0.5em;
    transition: all 0.5s ease;
    flex-basis: 100%;
}

.app-content {
    position: relative;
    display: flex;
    color: white;
    font-size: calc(10px + 2vmin);
    flex: 1 0 auto;
    height: 83%;
}
.app-content i {
    color: wheat;
    cursor: pointer;
    font-size: 0.7em;
    /* margin: 5px; */
    /* height: 1em; */
    /* width: 1em; */
    line-height: 1em;
    vertical-align: 1em;
}
.app-footer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: calc(8px + 2vmin);
    flex-shrink: 0;
    text-align: center;
    height: 7%;
    font-size: 1em;
    border-top: 1px solid wheat;
}
.app-footer a {
    padding: 0 7.5px;
}

a,
.app-link {
    color: #61dafb;
}

.user-info .user-content hr {
    border-top: 1px solid slategray;
    margin: -7px 0 7px 0;
}

.user-info .user-content .user-content-item {
    font-size: 0.5em;
}

/* Scrollbar */
html *::-webkit-scrollbar {
    width: 0.35em;               /* width of the entire scrollbar */
}

html *::-webkit-scrollbar-track {
    /* color of the tracking area */
    /* background: var(--app-bg); */
}

html *::-webkit-scrollbar-thumb {
    background-color: wheat;    /* color of the scroll thumb */
    border-radius: 20px;       /* roundness of the scroll thumb */
    /* creates padding around scroll thumb */
    /* border: 3px solid var(--app-bg); */
    border: 1px solid var(--app-bg);
}

@media (min-width: 768px) {
    .app-header h1 {
        font-size: 1em;
    }
}
@media (min-width: 1024px) {
    .app-header {
        justify-content: center;
        height: 7%;
    }
    .app-header .menu-hamburger {
        position: absolute;
        font-size: 1em;
    }
    .app-header .app-notice-banner {
        flex-basis: unset;
    }
    .app-content {
        height: 85%;
    }
    .app-footer {
        height: 5%;
    }
}