.generic-okay-form {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    background-color: rgb(40 44 52);
    border: 1px solid rgb(56, 56, 56);
    border-radius: 5px;
    box-shadow: 0px 0px 40px -10px #000;
    color: #fff;
    min-height: 200px;
    width: 25%;
    z-index: 999;
}
.generic-okay-form form {
    display: flex;
    flex-wrap: wrap;
    height: 100%;
}
.generic-okay-form form hr {
    flex-basis: 100%;
    height: 1px;
}
.generic-okay-form .form-field-title {
    font-size: 1.5em;
    padding: 20px 20px 0 20px;
}
.generic-okay-form .form-field-description {
    padding: 0 20px 0 20px
}
.generic-okay-form .button-group {
    text-align: center;
    padding: 0px 20px 20px 20px;
    width: calc(100%);
}
.generic-okay-form .button-group button {
    background-color: wheat;
    border: 1px solid wheat;
    color: #000;
    font-size: 26px;
    width: 200px;
    border-radius: 15px;
    box-shadow: 0px 0px 10px 0px #000;
    height: 100%;
}
.generic-okay-form .button-group button:hover {
    background-color: #d8c49e;
}
.generic-okay-form .button-group button:active {
    background-color: #b0a080;
}

.form-container .field-group-input {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
    width: min-content;
}
.form-container .form-group-background-info {
    max-height: 50vh;
    /* min-height: 20vh; */
    overflow: auto;
}
.form-container .form-group-background-info .field-group .checkbox-item label {
    font-size: 0.6em;
}
.form-container .field-group-input .form-field-title {
    flex-basis: 100%;
}
.form-container .field-group-input-split {
    display: flex;
    justify-content: space-between;
    line-height: 0.5em;
    padding-top: 2px;
}
.form-container .field-group input, .form-container .field-group-input input, .form-container .field-group-input-split input {
    background-color: unset;
    border: 1px solid var(--form-decoration);
    border-radius: 5px;
    padding: 0 10px;
    line-height: 0.6em;
    color: #fff;
}
.form-container .field-group input.custom-select-creatable__input {
    background-color: unset;
    border: 1px solid var(--form-decoration);
    border-radius: 5px;
    padding: 0 10px;
    line-height: 0.6em;
    color: var(--font) !important;
}
.form-container .field-group textarea {
    background-color: unset;
    border: 1px solid var(--form-decoration);
    border-radius: 5px;
    padding: 0 10px;
    color: #fff;
}
.form-container .field-group input:focus-visible, .form-container .field-group textarea:focus-visible {
    /* outline: 2px solid rgb(200, 181, 146); */
    outline: unset;
    /* outline: 5px solid red; */
}
.form-container .button-group button,
.form-container-edit .button-group button {
    color: var(--app-bg);
    font-size: 0.75em;
    background-color: var(--primary-decoration);
    border-radius: 5px;
    border: 0;
    margin-right: 25px;
    padding: 5px;
}
.form-container .button-group button[type="submit"],
.form-container-edit .button-group button[type="submit"],
.form-container .button-group button[type="reset"],
.form-container-edit .button-group button[type="reset"] {
    color: var(--font-color);
    background-color: unset;
}
.form-container .button-group button:hover,
.form-container-edit .button-group button:hover {
    /* background-color: #cfba94; */
    cursor: pointer;
}
.form-container .button-group button[type="submit"]:hover,
.form-container-edit .button-group button[type="submit"]:hover {
    font-style: italic;
    text-decoration: underline;
    background-color: unset;
}
.form-container .button-group button[type="reset"]:hover,
.form-container-edit .button-group button[type="reset"]:hover {
    font-style: italic;
    background-color: unset;
}
.form-container .button-group button[type="submit"]:active,
.form-container-edit .button-group button[type="submit"]:active,
.form-container .button-group button[type="reset"]:active,
.form-container-edit .button-group button[type="reset"]:active {
    background-color: unset;
}
.form-container .button-group button:hover,
.form-container-edit .button-group button:hover {
    background-color: #b0a080;
}
.form-container .button-group button:active,
.form-container-edit .button-group button:active {
    background-color: #9a8a6c;
}
.form-container .rc-time-picker,
.form-container .checkbox-item {
    display: flex;
}
.form-container textarea, .form-container input, .form-container .react-datepicker-wrapper {
    width: 100%;
    display: flex;
}
.form-container .checkbox-item input {
    margin-right: 15px;
    width: min-content;
}
.login-splash-screen {
    position: absolute;
    object-fit: cover;
    height: 100%;
    width: 100%;
}
.form-container-login-register {
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 200px;
}
.form-container-login-register ::placeholder {
    font-style: italic;
}
.form-container-login-register > form {
    display: flex;
    background-color: #1b1e23;
    border-radius: 30px;
    padding: 1.5em;
    flex-wrap: wrap;
    position: relative;
    opacity: .95;
}
.form-container-login-register > form .error-message.with-background, .form-container-login-register > form .form-error {
    color: red;
    font-size: 0.4em;
    margin: 10px auto;
    background-color: #191b21;
    padding: 5px 4px;
    text-align: center;
    border-radius: 5px;
    position: absolute;
    z-index: 1;
    top: 50%;
    left: -100px;
    transform: translate(-75%, -50%);
}
.form-container-login-register > form > .form-group-inputs {
    flex-basis: 100%;
}
.form-container-login-register > form > .form-group-inputs .field-group {
    position: relative;
    flex-basis: 100%;
    padding: 5px 0;
}
.form-container-login-register > form > .form-group-inputs .field-group input {
    background-color: var(--app-bg);
    border: 0;
    border-radius: 5px;
    color: #fff;
    font-size: 16px;
    padding: 5px;
}
.form-container-login-register > form > .form-group-inputs .field-group input:focus-visible {
    outline: none;
    box-shadow: 0px 0px 10px 0px wheat;
}
.form-container-login-register > form > hr {
    flex-basis: 100%;
}
.form-container-login-register > form > .button-group {
    flex-basis: 100%;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}
.form-container-login-register > form > .button-group #login-btn,
.form-container-login-register > form > .button-group #cancel-btn {
    flex-basis: 49%;
    padding: 0 10px;
    margin: 0;
}
.form-container-login-register > form > .button-group #register-btn,
.form-container-login-register > form > .button-group #reset-btn {
    background-color: unset;
    color: var(--font-color-cw);
    padding: unset;
    margin: unset;
    font-size: 0.5em;
    flex-basis: 100%;
    border: unset;
}
.form-container-login-register > form > .button-group #register-btn:hover,
.form-container-login-register > form > .button-group #reset-btn:hover {
    border: unset;
    font-style: italic;
}
.form-container-login-register > form > .button-group > button {
    background-color: #f5deb3;
    color: var(--app-bg);
    border: 1px solid var(--app-bg);
    border-radius: 5px;
    font-size: 1em;
    height: min-content;
    margin: 5px 2px;
}
@media (min-width: 1024px) {
    .form-container-login-register > form > .button-group > button {
        font-size: 0.6em;
    }   
}
@media (min-width: 1440px) {
    .form-container-login-register > form > .button-group > button {
        font-size: 0.6em;
    }   
}
.form-container-login-register > form > .button-group > button:hover {
    background-color: #cfba94;
    border: 1px solid #000;
}
.form-container-login-register > form > .button-group > button:active {
    background-color: #9a8a6c;
    border: 1px solid #000;
}

/* OTP container */
.form-container-login-register > form > .form-group-inputs .otp-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.form-container-login-register > form > .form-group-inputs .otp-container p,
.form-container-login-register > form > .form-group-inputs .pre-password-reset-header p {
    flex-basis: 100%;
    font-size: 0.5em;
}
.form-container-login-register > form > .form-group-inputs .otp-container .field-group {
    flex-basis: unset;
}
.form-container-login-register > form > .form-group-inputs .otp-container .field-group input {
    padding: 0;
    width: 40px;
}

/* Note edit form */
.form-container-edit.note-form {
    background-color: #171a1f;
    border-radius: 5px;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
    position: absolute;
}
.form-container-edit.note-form .note {
    overflow: unset !important;
}
.form-container-edit.note-form .note .header-banner {
    border-radius: 5px 5px 0 0;
}
.form-container-edit.note-form .note .header-banner input {
    background-color: #ffed89 !important;
    color: var(--app-bg);
    outline: unset;
    border: 1px solid #b9ac5f;
    border-radius: 5px;
}
.form-container-edit.note-form .note .note-content textarea {
    color: wheat;
    background-color: #171a1f;
    border-radius: 5px;
    margin-top: 5px;
    width: 100%;
}
.form-container-edit.note-form .note .note-content textarea:focus-visible {
    outline: unset;
}
.form-container-edit.note-form .note .note-content .note-date input {
    color: wheat;
    background-color: #171a1f;
    border: 1px solid gray;
    border-radius: 5px;
    width: 100%;
}
.form-container-edit.note-form .note .note-content input:focus-visible {
    outline: unset;
}
.form-container-edit.note-form .note .note-content .checkbox-item label {
    margin-left: 10px;
}

.password-container {
    position: relative;
}
.password-container .show-hide {
    position: relative;
}
/* Customize the label (the .password- .show-hide) */
.password-container .show-hide {
    display: block;
    position: absolute;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    user-select: none;
    top: 50%;
    right: 0;
    transform: translate(80%, -50%);
    height: 25px;
    width: 25px;
}

/* Hide the browser's default checkbox */
.password-container .show-hide input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 100%;
    width: 100%;
    z-index: 1;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
    border-radius: 5px;
}

/* On mouse-over, add a grey background color */
.password-container .show-hide:hover input ~ .checkmark {
    background-color: #ccc;
    border-radius: 5px;
}

/* When the checkbox is checked, add a blue background */
.password-container .show-hide input:checked ~ .checkmark {
    background-color: #2196F3;
    border-radius: 5px;
}

/* Create the checkmark/indicator (hidden when not checked) */
.show-hide .checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.password-container .show-hide input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.password-container .show-hide .checkmark:after {
    left: 10px;
    top: 4px;
    width: 8px;
    height: 14px;
    border: solid white;
    border-radius: 10px;
    border-width: 0 4px 4px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

/* Animal form */
.animal-form .form-group-background-info {
    display: flex;
    flex-wrap: wrap;
}
.animal-form .form-group-background-info .field-group.flex-100, .animal-form .form-group-background-info .field-group-input.flex-100,
.animal-form .form-group-background-info .field-group-input-split.field-group-input.flex-100 {
    flex-basis: 100%;
    display: flex;
    flex-wrap: wrap;
}
.animal-form .form-group-background-info .field-group.flex-100 .field-group, .animal-form .form-group-background-info .field-group-input.flex-100 .field-group,
.animal-form .form-group-background-info .field-group-input-split.field-group-input.flex-100 .field-group {
    max-width: 300px;
    margin: 0 15px 0 0;
}
.animal-form .form-group-background-info .field-group.flex-100 .field-group-input {
    width: unset;
    min-width: unset;
}
.animal-form .form-group-background-info .field-group.flex-100 .field-group-input:not(:first-child) {
    margin-left: unset;
}
@media (min-width: 1024px) {
    .password-container .show-hide {
        transform: translate(120%, -50%);
    }
}
@media (min-width: 1440px) {
    .animal-form .form-group-background-info .field-group.flex-100 .field-group-input:not(:first-child) {
        margin-left: 1em;
    }
}
.animal-form .form-group-background-info .field-group-input-split > *:not(:first-child) {
    margin-left: 1em;
}
.animal-form .form-group-background-info .field-group.flex-50, .animal-form .form-group-background-info .field-group-input.flex-50,
.animal-form .form-group-background-info .field-group-input-split.field-group-input.flex-50 {
    flex-basis: 50%;
}
.animal-form .form-group-background-info .field-group.flex-32, .animal-form .form-group-background-info .field-group-input.flex-32,
.animal-form .form-group-background-info .field-group-input-split.field-group-input.flex-32 {
    flex-basis: 32%;
}
.animal-form .field-group {
    /* max-width: 300px; */
}

/* Animal meter form */
.animal-meter-form .form-group-background-info .custom-select-creatable-container {
    flex-basis: 100%;
}
.animal-meter-form .form-group-background-info .meter-group {
    width: 100%;
}
.animal-meter-form .form-group-background-info .meter-group .meter {

}
.animal-meter-form .form-group-background-info .meter-group .button-group {
    margin: 0.5em 0 2em 0;
}
.animal-meter-form .form-group-background-info .form-field-error {
    color: red;
    font-size: 0.5em;
    background-color: var(--app-bg);
    padding: 5px;
    border-radius: 5px;
}

/* Ingredient form */
.field-group.ingredient-field-group {
    display: flex;
}
.field-group.ingredient-field-group > .form-field-title {
    text-decoration: underline;
}
.field-group.ingredient-field-group .field-group {
    flex-basis: 50%;
}
.field-group.ingredient-field-group .field-group input {
    /* flex-basis: 100%; */
}

/* Misc inputs */
.number-container {
    display: flex;
}