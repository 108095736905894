.color-scheme-switches {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.color-scheme-switches i.fa-palette {
    color: #008fff;
    font-size: 32px;
    flex-basis: 100%;
    text-align: center;
    padding: 10px;
    cursor: pointer;
}

.color-scheme-switches .swatches-picker {
    width: 150px;
}

.swatches-picker {

}

.typewriter-container {
    display: flex;
    align-items: center;
}

.loading {
    position: relative;
    height: 100%;
    width: 100%;
}
.loading > .loading-spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.loading > .loading-text {
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, 0%);
}
/* With background */
.loading > .loading-background {
    display: flex;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    justify-content: center;
    flex-wrap: wrap;
    background-color: var(--app-bg);
    border-radius: 10px;
    opacity: 0.95;
    padding: 10px;
}
.loading > .loading-background .loading-spinner {
    display: flex;
    justify-content: center;
    flex-basis: 100%;
}
.loading > .loading-background .loading-spinner > * {
    justify-content: center;
}
.loading > .loading-background .loading-text {

}
.loading-spinner {
    display: inline-block;
    width: 80px;
    height: 80px;
}
.loading-spinner:after {
    content: " ";
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid #fff;
    border-color: #fff transparent #fff transparent;
    animation: loading-spinner 1.2s linear infinite;
}
@keyframes loading-spinner {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
  