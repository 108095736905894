:root {
    --app-bg: #1b1e23;
    --app-bg-highlight: #282c34;
    /* --app-bg-highlight: #534e47; */
    --app-bg-highlight-accent: #3d424d;
    --app-bg-dark: #131518;
    --app-bg-selected: #4a515f;
    /* --link-text: #8ac8d9; */
    /* --link-text: ##d9d2c8; */
    /* --link-text: #5b92a0; */
    --link-text: #70b1c2;
    --header-text: #f5deb3;
    /* --font: #e9dfcd; */
    --font: #b9b7b7;
    --font-color-cw: #b9b7b7;
    --font-off-white: #dadada;
    --primary-decoration: #f5deb3;
    --secondary-decoration: #766b57;
    --form-decoration: #d4c6ad;
}
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Unbounded', 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html,
body,
#root,
.App {
    height: 100%;
}

a {
    text-decoration: none !important;
    color: var(--link-text) !important;
}

.App h1, .App h2, .App h3, .App h4, .App h5, .App h6 {
    color: var(--header-text);
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@font-face {
    font-family: 'SwankyMooMoo';
    src: 
        local('SwankyMooMoo'),
        url(./../../fonts/SwankyandMooMoo-Regular.ttf)
        format('truetype');
}

@font-face {
    font-family: 'Unbounded';
    src: 
        local('Unbounded'), 
        url(./../../fonts/Unbounded-VariableFont_wght.ttf)
        /* url("./../fonts/Unbounded-VariableFont_wght.ttf") */
        format('truetype');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
}