.settings-container {
    display: flex;
}
.settings-container .settings-menu {
    display: flex;
    align-content: flex-start;
    flex-wrap: wrap;
    height: 100%;
    width: 35%;
}
.settings-container .settings-menu > a > div {
    font-size: 0.75em;
    padding: 5px 10px;
}
.settings-container .settings-menu > a:hover {
    background-color: var(--app-bg-highlight-accent);
    border-radius: 5px;
}
.settings-container .settings-menu > a.selected {
    background-color: var(--app-bg-highlight);
    border-radius: 5px;
}
.settings-container .settings-menu > * {
    flex-basis: 100%;
}
.settings-container .vertical-ruler {
    background-color: wheat;
    margin: 0px 10px;
    height: 100%;
    width: 1px;
}
.settings-container section.settings {
    width: 55%;
}
.settings-container section.settings input {
    border: 0;
    border-radius: 10px;
    background-color: #1b1e23;
    color: white;
    height: 100%;
    outline: unset;
}
.settings-container section.settings p, .settings-container section.settings > ul,
.settings-container section.settings li {
    font-size: 0.7em;
}

/* Account information */
.settings-container section.settings .account-information p {
    margin: 0;
    padding: 0;
}
.settings-container section.settings .account-information .account-information-content {
    font-size: 0.8em;
}
.settings-container section.settings .account-information span.settings-title {
    color: wheat;
    padding-right: 10px;
}
.settings-container section.settings .account-information .account-details {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.settings-container section.settings .account-information .account-details > * {
    background-color: var(--app-bg-highlight);
    border-radius: 15px;
    padding: 5px;
}
.settings-container section.settings .account-information .account-details > * > p {
    display: flex;
    align-items: center;
    align-content: center;
}

/* Preferences */
.toggle-label {
    display: flex;
    align-items: center;
}
.toggle-label label {
    font-size: 0.5em;
    line-height: 1em;
    margin-right: 10px;
}

/* Privacy */
.account-privacy-content {
    font-size: 0.75em;
}
@media (min-width: 1440px) {
    .settings-container .settings-menu {
        width: 20%;
    }
    .settings-container section.settings {
        width: 80%;
    }
    .settings-container section.settings .account-information .account-information-content {
        font-size: 1em;
    }
}