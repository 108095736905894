/* Options */
.options, .options-menu {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 19;
    display: flex;
}
.options-menu .options-menu-content {
    display: none;
}
.options-menu .options-menu-content.show {
    display: block;
    position: absolute;
    top: 0;
    right: 1.5em;
    background-color: wheat;
    color: black;
    padding: 1em;
    font-size: 0.5em;
    border-radius: 5px;
    border: 1px solid black;
}
.options-menu .options-menu-content.show .option:hover {
    text-decoration: underline;
    cursor: pointer;
}
.options-menu > i {
    font-size: 1.5em;
    padding: 0 10px;
}

/* Notifications */
.general-notification {
    background-color: #1e2127;
    border-radius: 0 0 0 15px;
    border-left: 5px solid #14161a;
    border-bottom: 5px solid #14161a;
    /* border-right: 5px solid #14161a; */
    padding: 5px 10px;
}
.general-notification .header {
    font-size: 0.7em;
    font-weight: bold;
    /* color: #ffd400; */
}
.general-notification .header h3 {
    padding-right: 25px
}
.general-notification .text {
    font-size: 0.5em;
    text-align: left;
    white-space: pre-line;
}

/* Release counter */
.release-container-wrapper {
    display: flex;
    height: 4vh;
    align-items: center;
    justify-content: start;
    overflow: hidden;
    z-index: 98;
    position: absolute;
    left: 6vw;
    font-size: 0.75em;
    color: wheat;
}
.release-container-wrapper .release-container-input {
    display: flex;
    justify-content: start;
}
.release-container-wrapper .release-container-input p {
    margin: 0;
    width: max-content;
    padding-right: 25px;
}
.release-container-wrapper .release-container-input .count-input {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin: 0;
}
.release-container-wrapper .counter {
    display: flex;
    width: 50%;
    align-items: flex-end;
    justify-content: center;
    height: 100%;
    border-bottom: 1px solid wheat;
    border-top: 1px solid wheat;
}
.release-container-wrapper .after-text p {
    margin: 0;
    width: max-content;
    padding-left: 5px;
}
.release-container-wrapper .counter .counter-item-wrapper {
    display: flex;
    height: 24px;
}
.release-container-wrapper .counter .counter-item-wrapper .counter-item {
    position: relative;
    width: 24px;
}
.release-container-wrapper .counter .counter-item-wrapper .counter-item .count-number {
    position: absolute;
    inset: 0;
    display: flex;
    justify-content: center;
}

/* Securtity Clearance */
.no-clearance-note {
    position: relative;
    height: 100%;
    width: 100%;
}
.no-clearance-note .no-clearance-wrapper {
    background-color: var(--app-bg-highlight);
    position: absolute;
    transform: translate(-50%, -50%);
    width: 50%;
    top: 50%;
    left: 50%;
    text-align: center;
    border-radius: 15px;
    padding: 10px;
}
.no-clearance-wrapper img {
    height: 50vh;
    margin: 25px 0;
    border-radius: 15px;
}
.grid-max-no-margins {
    display: grid;
    gap: 10px;
    padding: 10px 0;
}
.grid-max-no-margins .grid-item {
    background-color: var(--app-bg-highlight);
    border-radius: 5px;
    padding: 10px;
}
.grid-max-no-margins .grid-item {
    font-size: 0.65em;
}

/* Under construction */
.under-construction-container {
    height: 100%;
    width: 100%;
    position: relative;
}
.under-construction-container .under-construction-image {
    height: 100%;
    width: 100%;
    object-fit: cover;
    /* filter: blur(4px); */
}
.under-construction-container hr {
    margin: 0;
}
.under-construction-container .image-shadow {
    box-shadow: inset 80px -20px 100px 120px #000000;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    content: "";
    z-index: 1;
}
.under-construction-container .under-construction-text {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 10px;
    opacity: 0.95;
    padding: 0.5em;
    z-index: 2;
    font-size: 1.25em;
    color: var(--font-color-cw);
    width: 60%;
}
.under-construction-container .under-construction-text .blurb {
    font-size: 0.75em
}
.under-construction-container .under-construction-swipe-background {
    background-color: var(--app-bg);
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: -45%;
    z-index: 2;
    border-right: 1px solid var(--primary-decoration);
    transform: skewX(-10deg);
    transform-origin: 100%;
}

/* Media queries */
@media (min-width: 1024px) {
    .under-construction-container .under-construction-text {
        top: 50%;
        left: 25%;
        transform: translate(-50%, -50%);
        width: unset;
    }
    .release-container-wrapper {
        left: 8vw;
        font-size: 0.5em;
    }
}
@media (min-width: 1920px) {
    .release-container-wrapper {
        left: 6vw;
        font-size: 0.75em;
    }
}