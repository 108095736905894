.modal-container {
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #e9dfcd;
    /* background-color: rgb(40 44 52); */
    /* background-color: #3c3c3c; */
    /* border: 1px solid var(--primary-decoration); */
    box-shadow: 0px 0px 50px 15px #000;
    border-radius: 5px;
    z-index: 20;
    max-width: 95%;
    padding: 5px;
    overflow: auto;
    width: 95%;
    background: linear-gradient(0deg, hsla(220, 7%, 18%, 1) 25%, rgb(116, 114, 86) 100%);
    background: -webkit-linear-gradient(90deg, hsla(220, 7%, 18%, 1) 25%, rgb(116, 114, 86) 100%);
    
}
@media (min-width: 1024px) {
    .modal-container {
        max-width: 50%;
        width: 50%;
        width: unset;
    }
}
/* .modal-container > * {
    position: relative;
} */
.modal-container > h3 {
    text-align: center;
}
.modal-container > hr {
    border-color: wheat;
    margin: 0px 0;
}
.modal-container > p, .modal-container .form-container {
    padding: 10px;

}
.modal-container > p {
    font-size: 1em;
    padding: 0 10px;
    margin: 0 auto;
    max-width: 100%;
    text-align: center;
}
.modal-container .form-container hr {
    margin: 10px 0;
}
.modal-container .form-container .button-group {
    display: flex;
}
.modal-container .form-container .field-group {
    display: flex;
    flex-wrap: wrap;
    padding: 10px 0;
}
.modal-container .form-field-title {
    display: flex;
    font-size: 1em;
    flex-basis: 100%;
}
.modal-container input, .modal-container textarea {
    font-size: 1em;
}
.modal-container .select__control, .modal-container .custom-select-creatable__control {
    background-color: unset;
    border: 1px solid var(--secondary-decoration);
}
.modal-container .select__control, .modal-container .select__menu, .modal-container .custom-select-creatable-container {
    font-size: 1em;
    max-width: 100%;
}
.modal-container .select__control, .modal-container .select__menu, .modal-container .custom-select-creatable-container .custom-select-creatable__multi-value__label {
    overflow: auto;
}
.modal-container .select__control .select__value-container .select__single-value {
    color: var(--font);
}
.modal-container .form-error {
    font-size: 0.9em;
    color: #ff4000;
}
.modal-container .required-asterisk {
    color: #ffd400;
}
.modal-container .custom-select-creatable-container.ingredient-select,
.modal-container .select-container.ingredient-select {
    width: 100%;
}
.modal-container .custom-select-creatable-container.ingredient-select .custom-select-creatable__menu,
.modal-container .select-container.ingredient-select .select__menu {
    display: flex;
    position: relative;
    top: unset;
}
.modal-container .custom-select-creatable-container.ingredient-select .custom-select-creatable__menu-list,
.modal-container .select-container.ingredient-select .select__menu-list {
    display: flex;
    width: 100%;
}
.modal-container .custom-select-creatable-container.ingredient-select .custom-select-creatable__menu .custom-select-creatable__option,
.modal-container .select-container.ingredient-select .select__menu .select__option {
    display: flex;
    justify-content: center;
}
.modal-container .select-container {
    width: 100%;
}
.modal-container .select__menu {
    /* position: relative; */
    top: unset;
}
.modal-container .select__menu, .modal-container .custom-select-creatable__menu-list {
    background-color: rgb(40 44 52);;
    border: 1px solid rgb(56, 56, 56);
    box-shadow: 0px 0px 40px -10px #000;
}
.modal-container .select__menu .select__option--is-focused, 
.modal-container .custom-select-creatable__menu-list .custom-select-creatable__option--is-focused {
    background-color: #516481;
    border: 1px solid rgb(56, 56, 56);
    box-shadow: 0px 0px 40px -10px #000;
}
.modal-container-tooltip {
    position: absolute;
    top: 45%;
    left: 50%;
    /* transform: translate(-50%, -50%); */
    background-color: rgb(40 44 52);;
    border: 1px solid rgb(56, 56, 56);
    border-radius: 15px;
    box-shadow: 0px 0px 40px -10px #000;
    font-size: 0.5em;
    max-width: 400px;
    z-index: 20;
}
/* .modal-container-tooltip > * {
    position: relative;
} */
.modal-container-tooltip > h3 {
    text-align: center;
}
.modal-container-tooltip > hr {
    border-color: wheat;
    margin: 0px 0;
}
.modal-container-tooltip > p, .modal-container-tooltip .form-container {
    padding: 0 20px;
    margin: 5px 0;
}
.modal-container-tooltip > p {
    padding-bottom: 25px;
}
.modal-container-tooltip .form-container {
    margin-bottom: 20px;
}
.modal-container-tooltip .form-field-title {
    display: flex;
}
.modal-container-tooltip .form-error {
    font-size: 0.5em;
    color: #ffd400;
}
.modal-container-tooltip .required-asterisk {
    color: #ffd400;
}
.modal-container-tooltip .select__menu {
    background-color: rgb(40 44 52);;
    border: 1px solid rgb(56, 56, 56);
    box-shadow: 0px 0px 40px -10px #000;
}
.modal-container-tooltip .select__menu .select__option--is-focused {
    background-color: #516481;
    border: 1px solid rgb(56, 56, 56);
    box-shadow: 0px 0px 40px -10px #000;
}
.modal-container-tooltip .modal-buttons {
    padding: 3px 2px;
    width: 100%;
}
.modal-relative-wrapper {
    position: relative;
    height: 100%;
    width: 100%;
}
.modal-relative-wrapper .btn-exit {
    z-index: 100;
}
/* Modal Garden Layout Popup */
.modal-garden-container {
    border: 1px solid wheat;
    border-radius: 10px;
    /* background: -moz-linear-gradient(top, rgba(40, 44, 52, 1), rgba(245, 222, 179), rgba(40, 44, 52, 1));
    background: -webkit-linear-gradient(top, rgba(40, 44, 52, 1), rgba(245, 222, 179), rgba(40, 44, 52, 1));
    background: linear-gradient(to bottom, rgba(40, 44, 52, 1), rgb(245, 222, 179), rgba(40, 44, 52, 1)); */
    height: 60vh;
    width: 60vh;
    background-color: #2f333d;
}
.modal-garden-container .garden-card-image-container .garden-card-title h3 {
    color: var(--header-text);
}
.modal-garden-container header {
    background: linear-gradient(to bottom, rgb(40, 44, 52), rgb(47, 51, 61));
    border-radius: 10px 10px 0 0;
    height: 5%;
    width: 100%;
}
.modal-garden-container .modal-garden-content {
    height: 95%;
    width: 100%;
    /* background-color: wheat; */
}
.modal-garden-container footer {
    background: linear-gradient(to top, rgb(40, 44, 52), rgb(47, 51, 61));
    border-radius: 0% 0% 10px 10px;
    height: 10%;
    width: 100%;
}
.modal-garden-container .garden-card-image {
    height: 95%;
    width: 100%;
    object-fit: contain;
}
.modal-garden-container .garden-card-image-container {
    height: 100%;
    width: 100%;
}
.modal-garden-container .garden-card-title {
    position: absolute;
    top: -5px;
    width: 100%;
}
.modal-garden-container .garden-card-title .header-text-container {
    position: relative;
    margin: 0 -5px;
}
.modal-garden-container .garden-card-title .header-text-container .header-left-line {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0, -50%);
    border-bottom: 1px solid wheat;
    height: 1px;
    width: 10%;
}
.modal-garden-container .garden-card-title .header-text-container h3 {
    margin: 0 auto;
    text-align: center;
    width: 80%;
    border-left: 1px solid wheat;
    border-right: 1px solid wheat;
    border-bottom: 1px solid wheat;
}
.modal-garden-container .garden-card-title .header-text-container .header-right-line {
    position: absolute;
    right: 0;
    top: 0;
    top: 50%;
    transform: translate(0, -50%);
    border-bottom: 1px solid wheat;
    height: 1px;
    width: 10%;
}

/* Animal icon modal */
.modal-container.animal-icon-info .animal-icons {
    display: grid;
    /*grid-template-rows: repeat(6, 1fr);*/
    grid-template-columns: repeat(6, 1fr);
    gap: 5px;
}
.modal-container.animal-icon-info .animal-icons .animal-icon {
    text-align: center;
}

/* Feedback modal */
.modal-container.feedback-modal {
    font-size: 2em;
}

/* Cooking ingredient modal */
.modal-container.modal-cooking-ingredient .form-container .form-group-background-info,
.modal-container.modal-grocery-ingredient .form-container .form-group-background-info {
    min-height: 20vh;
}

/* Cooking add recipe modal */
.modal-container .custom-select-creatable-container.verticle-select-field {
    
}
.modal-container .custom-select-creatable-container.verticle-select-field .custom-select-creatable__multi-value {
    flex-basis: 100%;
    background-color: unset;
}
.modal-container .custom-select-creatable-container.verticle-select-field .custom-select-creatable__multi-value .custom-select-creatable__multi-value__label {
    background-color: hsl(0, 0%, 90%);
}
.modal-container .custom-select-creatable-container.verticle-select-field .custom-select-creatable__multi-value .custom-select-creatable__multi-value__remove {
    color: red;
    background-color: hsl(0, 0%, 90%);
}

@media (min-width: 1024px) {
    .modal-container .form-field-title,
    .modal-container input, .modal-container textarea,
    .modal-container .select__control, .modal-container .select__menu, .modal-container .custom-select-creatable-container {
        font-size: 0.5em;
    }
}