.top-bar {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-end;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid wheat;
    margin-bottom: 10px;
}
.top-bar.no-margin {
    margin: 0;
}
.top-bar h2 {
    padding: 0;
    margin: 0;
    font-size: 0.8em;
}
.top-bar-main-dash {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-end;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid wheat;
    margin: 5px 0 10px 0;
    color: var(--header-text);
}
.content-container {
    position: relative;
    height: 88%;
    width: 100%;
}
.content-bill, .content-task, .content-worktime {
    position: relative;
    background-color: #252931;
    border-radius: 10px;
    padding: 10px;
    margin: 5px;
}
.content-bill i.fa-x, .content-task i.fa-x, .content-worktime i.fa-x {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 999;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    height: 16px;
}
.content-bill h4, .content-task h4 {
    display: flex;
    flex-wrap: wrap;
    padding-right: 20px;
}
.content-bill .title, .content-task .title, .content-worktime .title {
    line-height: 0.9em;
    margin-bottom: 10px;
}
.content-task .dueDate {
    flex-basis: 100%;
    font-size: 0.5em;
}
.content-bill .reminder, .content-task .reminder {
    display: flex;
    align-items: center;
    flex-basis: 100%;
    font-size: 0.5em;
}
.content-bill .description, .content-task .description, .content-worktime .worktime-data {
    font-size: 0.75em;
    background-color: #323846;
    border-radius: 10px;
    padding: 10px;
    margin: 10px 0;
}
.content-worktime h4 {
    flex-wrap: wrap;
}
.content-worktime h4 > div {
    flex-basis: 100%;
}
.fa-xs {
    line-height: 1em;
}
.fake-checkbox-false {
    background-color: #eee;
    border-radius: 25px;
    margin: 0 5px;
    height: 15px;
    width: 15px;
}
.fake-checkbox-true {
    position: relative;
    background-color: #2196F3;
    border-radius: 25px;
    margin: 0 5px;
    height: 15px;
    width: 15px;
}
.fake-checkbox-true::after {
    content: "";
    position: absolute;
    display: block;
    left: 6px;
    top: 2px;
    width: 4px;
    height: 8px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

.kpi-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    border-bottom: 1px solid wheat;
    font-size: 0.7em;
    padding-bottom: 10px;
}

.homepage-container {
    height: 100%;
    width: 100%;
}
.homepage-container .latest {
    height: 100%;
    padding: 1% 1% 1% 1%;
}
.homepage-container .latest .update, .homepage-container .latest .update .body {
    height: 100%;
}
.homepage-container .latest .update .body {
    height: 100%;
    overflow: auto;
}
.homepage-container .latest .update .body .change-list {
    display: grid;
    /* 3 column grid with each column 33% */
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 10px;
    padding: 10px;
    background-color: #16191d;
    border-radius: 10px;
}
.homepage-container .latest .update .body .upcoming-change-list .category-card ul li:not(.selected) {
    list-style-type: disc;
}
.homepage-container .latest .update .body .upcoming-change-list ul > li:not(.selected) {
    list-style-type: disclosure-closed;
}
.homepage-container .latest .update .body .upcoming-change-list ul > li {
    list-style-type: disclosure-open;
}
.homepage-container .latest .update .body .upcoming-change-list ul > ul > li:not(.selected),
.homepage-container .latest .update .body .upcoming-change-list ul > ul > li {
    list-style-type: disc;
}
/* Show child ul when this has selected class */
.homepage-container .latest .update .body .upcoming-change-list > ul > li:hover {
    cursor: pointer;
    text-decoration: underline;
}
.homepage-container .latest .update .body .upcoming-change-list > ul > li.selected {
    
}
.homepage-container .latest .update .body .upcoming-change-list > ul > li.selected + ul {
    display: block;
    font-size: 0.75em;
}
.homepage-container .latest .update .body .upcoming-change-list > ul > li.selected + ul li:nth-child(odd) {
    background-color: #323846;
    border-radius: 10px;
    padding: 10px;
    margin: 10px 0;
}
.homepage-container .latest .update .body .upcoming-change-list > ul > li.selected + ul li:nth-child(even) {
    background-color: #1d2027;
    border-radius: 10px;
    padding: 10px;
    margin: 10px 0;
}
.homepage-container .latest .update .body .upcoming-change-list > ul > li:not(.selected) + ul {
    display: none;
}
.homepage-container .latest .update .body .upcoming-change-list > ul.selected > ul {
    display: block;
}
.homepage-container .latest .update .body .change-list .category-card {
    background-color: var(--app-bg-highlight);
    border-radius: 10px;
    padding: 10px;
    width: 100%;
}
.homepage-container .latest .update .body .change-list .category-card .category-content {
    font-size: 1rem;
    color: var(--font-color-cw);
}
.homepage-container .latest .update .body .change-list .category-card .category {
    background: #b9b7b7;
    color: var(--app-bg-highlight);
    border-radius: 5px;
    width: unset;
    padding: 0 10px;
}
.homepage-container .latest .update .body .centered-list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.homepage-container .latest .update .body .upcoming-change-list,
.homepage-container .latest .update .body .change-log {
    background-color: var(--app-bg-highlight);
    color: var(--font-color-cw);
    padding: 10px;
    border-radius: 10px;
    width: 90%;
}
.homepage-container .latest .update .body .change-log .change-log-title {
    color: wheat;
    font-size: 1em;
    width: 100%;
}
.homepage-container .latest .update .body .change-log .change-log-date {
    font-size: 0.5em;
}
.homepage-container .latest .update .body .change-log .change-log-list {
    font-size: 0.75em;
}
.homepage-container .latest .update .body .change-log .change-log-seperator {
    background-color: wheat;
    padding: 3px;
    border-radius: 10px;
    margin-bottom: 0.25em;
    width: 100%;
}
.homepage-container .latest .update .body .change-log .change-log-list li:nth-child(odd) {
    /* background-color: #323846; */
    /* border-radius: 10px; */
    padding: 10px;
    margin: 10px 0;

}
.homepage-container .latest .update .body .change-log .change-log-list li:nth-child(even) {
    /* background-color: #1d2027; */
    /* border-radius: 10px; */
    padding: 10px;
    margin: 10px 0;
}
.homepage-container .latest .update .body .date {
    font-size: 0.5em;
}
.homepage-container .latest .update .body .title {
    font-size: 1.5em;
    margin-bottom: 10px;
    color: wheat;
}

.about-container {
    height: 100%;
    width: 100%;
}

.about-container h3 {
    padding: 0;
}
.about-container p {
    padding: 0;
    margin: 0 0 25px 0;
}

.lifted-container {
    border: 1px solid wheat;
    border-top: unset;
    box-shadow: 0px 10px 15px -10px black;
    padding: 1em;
    margin: 0 auto;
    width: 50%;
}
.lifted-container-80 {
    border: 1px solid wheat;
    border-top: unset;
    box-shadow: 0px 10px 15px -10px black;
    padding: 1em;
    margin: 0 auto;
    width: 80%;
}
.lifted-container-95 {
    border-left: 1px solid wheat;
    border-right: 1px solid wheat;
    box-shadow: 0px 10px 15px -10px black;
    padding: 1em;
    margin: 0 auto;
    width: 95%;
}

.text-align-center {
    text-align: center;
}

.common-container {
    height: 100%;
    width: 100%;
    padding: 0 0.5em;
    overflow: auto;
}
.common-container h1, .common-container h2, .common-container h3, .common-container h4, .common-container h5 {
    color: wheat;
}

.word-highlight {
    color: #84c9ff;
}
.not-found-container {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    font-size: 0.75em;
    height: 100%;
    width: 100%
}
.not-found-container .divider {
    border-right: 1px solid wheat;
    height: 25px;
    margin: 0 10px;
}
.not-found-container .title {

}
/* Text color, decoration, and style changes */
.text-highlight {
    color: #b2b000;
}
.text-frost {
    color: #005fa7;
}
.text-dry {
    color: #a70000;
}

@media screen and (min-width: 768px) {
    .about-container h3 {
        font-size: .5em;
    }
    .top-bar {
        /* height: 7%; */
    }
    .top-bar .btn-tab {
        font-size: 2em
    }
}
@media screen and (min-width: 1024px) {
    .content-container {
        height: 93%;
    }
    .top-bar .btn-tab {
        font-size: 1.5em
    }
    .homepage-container .latest .update .body .change-list {
        grid-template-columns: repeat(2, 1fr);
    }
    .homepage-container .latest .update .body .change-log .change-log-title {
        width: max-content;
    }
    .homepage-container .latest .update .body .upcoming-change-list,
    .homepage-container .latest .update .body .change-log {
        width: 80%;
    }
    .homepage-container .latest .update .body .change-list .category-card .category {
        width: max-content;
    }
}
@media screen and (min-width: 1440px) {
    .top-bar h2 {
        font-size: 1em;
    }
}
@media screen and (min-width: 1920px) {
    .homepage-container .latest .update .body .change-list {
        grid-template-columns: repeat(3, 1fr);
    }
    .homepage-container .latest .update .body .upcoming-change-list,
    .homepage-container .latest .update .body .change-log {
        width: 90%;
    }
}

/* iPad Pro 12.9 - horizontal */
@media screen and (min-width: 2731px) {

}