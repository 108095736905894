/* A generic search bar */
.generic-search, .search-bar {
    background-color: var(--app-bg);
    margin: 0.5rem 0;
    border: 1px solid var(--app-bg-highlight);
    border-radius: 10px;
    color: white;
    font-size: 1em;
}
.gardening-container .generic-search, .gardening-container .search-bar {
    font-size: 2em;
}
.generic-search:focus-visible, .search-bar {
    outline: unset;
}
.select-main {
    background-color: var(--app-bg-highlight);
    border-radius: 5px;
    color: wheat;
}
@media (min-width: 1024px) {
    .generic-search, .gardening-container .generic-search, .gardening-container .search-bar {
        font-size: 1em;
    }
}